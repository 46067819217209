import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch, useLocation} from 'react-router-dom'
import {FallbackView} from '_metronic/partials'
import {useLayout} from '_metronic/layout/core'

export function PrivateRoutes() {
  const AccountPage = lazy(() => import('../features/accounts/AccountPage'))
  const ClientAccountPage = lazy(() => import('../features/clientAccounting/clientAccountPage'))
  const SubscriptionPlanPage = lazy(
    () => import('app/features/subscriptionPlan/SubscriptionPlanPage')
  )
  const ChangeLogPage = lazy(() => import('app/features/changeLog/ChangeLogPage'))
  const ReportsPage = lazy(() => import('app/features/reports/ReportsPage'))
  const InvoicePage = lazy(() => import('app/features/invoices/InvoicePage'))
  const UsersPage = lazy(() => import('app/features/users/UsersPage'))
  const AnalyticsPage = lazy(() => import('app/features/analytics/AnalyticsPage'))
  const AccountLinkCreationPage = lazy(
    () => import('app/features/accountLinkCreation/AccountLinkCreationPage')
  )
  const MigrationPage = lazy(() => import('app/features/migration/MigrationPage'))

  const CertificatePage = lazy(() => import('app/features/ssl/CertificatePage'))

  const {menus} = useLayout()

  const loc = useLocation()

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {menus.length > 0 &&
          menus.map((menu: any, index: any) => {
            if (menu.code === 'subscriptionPlan')
              return <Route path='/subscriptionPlan' component={SubscriptionPlanPage} />

            if (menu.code === 'invoices') return <Route path='/invoices' component={InvoicePage} />

            if (menu.code === 'accountLink')
              return <Route path='/creationLink' component={AccountLinkCreationPage} />

            if (menu.code === 'changeLog')
              return <Route path='/changeLog' component={ChangeLogPage} />

            if (menu.code === 'linkUsers') return <Route path='/users' component={UsersPage} />

            if (menu.code === 'analytics')
              return <Route path='/analytics' component={AnalyticsPage} />

            if (menu.code === 'clientAccounting')
              return <Route path='/client/accounting' component={ClientAccountPage} />

            if (menu.code === 'reports') return <Route path='/reports' component={ReportsPage} />

            // if (menu.code === 'migration') return <Route path='/migration' component={MigrationPage} />

            // if (menu.code === 'ssl') return <Route path='/ssl' component={CertificatePage} />

            return <Route path='/accounts' component={AccountPage} />
          })}

        <Route path='/ssl' component={CertificatePage} />
        <Route path='/migration' component={MigrationPage} />
        <Redirect from='/' to={loc.pathname ?? '/accounts'} />
        <Redirect from='/auth' to='/accounts' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
