import {gql} from '@apollo/client'

export const GET_OVERTIME_STORE_CREATION = gql`
  query getStoreCreations($fromDate: Date!, $toDate: Date!, $tagId: UUID, $timeZone: String!) {
    getStoreCreations(fromDate: $fromDate, toDate: $toDate, pTagId: $tagId, pTimeZone: $timeZone) {
      nodes {
        creationDate
        totalCount
      }
      totalCount
    }
  }
`
export const GET_OVERTIME_INVOICE_REVENUE = gql`
  query GetStoreInvoiceRevenue($fromDate: Date!, $toDate: Date!, $paymentStatus: String) {
    getStoreInvoiceRevenue(fromDate: $fromDate, toDate: $toDate, pStatus: $paymentStatus) {
      nodes {
        invoiceDate
        revenue
      }
      totalCount
    }
  }
`
