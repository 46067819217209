import {createSlice} from '@reduxjs/toolkit'

export type DialogState = {
  show: boolean
  title?: string
  children?: React.ReactNode
  options: {
    okButton: {
      label: string
      onClick?: () => void
    }
  }
}

const initialState: DialogState = {
  show: false,
  options: {
    okButton: {
      label: 'OK',
    },
  },
}

const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    openDialog: (state, action) => {
      return {
        ...state,
        show: true,
        ...action.payload,
      }
    },
    closeDialog: () => initialState,
  },
})

export const {openDialog, closeDialog} = dialogSlice.actions

export default dialogSlice.reducer
