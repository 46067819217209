import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'
import * as auth from 'app/features/auth'
import misc from 'app/redux'
import subscriptions from 'app/features/subscriptionPlan/redux'
import accounts from 'app/features/accounts/redux'
import invoices from 'app/features/invoices/redux'
import users from 'app/features/users/redux'
import settings from 'app/features/settings/redux'
import analytics from 'app/features/analytics/redux'
import reports from 'app/features/reports/redux'
import accountCreations from 'app/features/accountLinkCreation/redux'
import clientAccounts from 'app/features/clientAccounting/redux'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  misc,
  subscriptions,
  accounts,
  invoices,
  users,
  settings,
  analytics,
  reports,
  accountCreations,
  clientAccounts,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
