import {gql} from '@apollo/client'

export const GET_ACCOUNT = gql`
  query GetAccount($id: UUID!) {
    account(id: $id) {
      id
      name
      companyName
      phone
      storeOwner
      addressLine2
      addressLine1
      city
      stateId
      countryId
      timeZone
      zipCode
      email
      customerEmail
      unitSystem
      weightUnit
    }
  }
`

export const CREATE_ACCOUNT = gql`
  mutation CreateAccount($account: AccountInput!) {
    createAccount(input: {account: $account}) {
      clientMutationId
    }
  }
`

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($id: UUID!, $account: AccountPatch!) {
    updateAccount(input: {id: $id, patch: $account}) {
      clientMutationId
    }
  }
`
export const GET_STORE = gql`
  query GetStore($id: UUID!) {
    getStores(pStorePlanId: $id) {
      nodes {
        id
        name
        transactionFee
        fee
        posTransactionFee
        storePlanId
        companyName
        feeType
      }
    }
  }
`
export const UPDATE_STORE_PLAN = gql`
  mutation UpdateStore($id: UUID!, $patch: StorePlanPatch!) {
    updateStorePlan(input: {patch: $patch, id: $id}) {
      clientMutationId
    }
  }
`

export const UPDATE_STORE = gql`
  mutation UpdateStore($id: UUID!, $patch: StorePatch!) {
    updateStore(input: {patch: $patch, id: $id}) {
      clientMutationId
    }
  }
`

export const UPDATE_STORE_TAG = gql`
  mutation UpdateStoreTag($id: UUID!, $patch: StoreTagPatch!) {
    updateStoreTag(input: {patch: $patch, id: $id}) {
      clientMutationId
    }
  }
`
