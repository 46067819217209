import apolloClient from 'apollo-client'
import {GET_FEE_SPLIT_UP_DETAIL_REPORT, GET_TOP_FEE_SPLIT_UP_REPORT} from './graphql'

class ReportsService {
  getTopFeeReports = async (filter: any) => {
    const {data, errors} = await apolloClient.query({
      query: GET_TOP_FEE_SPLIT_UP_REPORT,
      variables: filter,
    })
    if (errors) {
      throw errors
    }
    return data.getTopFeeSplitUpReport.nodes
  }

  filterOverTimeFeeReports = async (filter: any) => {
    const {data, errors} = await apolloClient.query({
      query: GET_FEE_SPLIT_UP_DETAIL_REPORT,
      variables: filter,
    })
    if (errors) {
      throw errors
    }
    const {totalCount, nodes} = data.getFeeSplitUpDetailReport
    return {data: nodes, totalCount}
  }
}

const reportsService = new ReportsService()
export default reportsService
