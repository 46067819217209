export const eventNames = {
  invoicePayment: 'invoicePayment',
}

export const roleTypes = {
  superAdmin: 'SUPER_ADMIN',
  paymnetAdmin: 'PAYMENT_ADMIN',
  customerSupport: 'CUSTOMER_SUPPORT',
  none: 'NONE',
}

export const reportTypes = {
  clients: 'clients',
  feeSplitUpReports: 'fee_split_up_reports',
  storeCreation:'store_creation',
  invoiceRevenue:'invoice_revenue',
  clientAccounting:'client_accounting'
}
