import apolloClient from 'apollo-client'
import {
  CREATE_STORE_PAYMENT,
  DELETE_STORE_PAYMENT,
  GET_BILLING_ADDRESSES,
  GET_STORE,
  GET_STORE_PAYMENTS,
  UPDATE_STORE_PAYMENT_METHOD_DEFAULT,
} from './graphql'
import {mapToCreateBillingAccount} from './helper'

class PaymentService {
  async getStorePayments(filter: any) {
    const {data} = await apolloClient.query({
      query: GET_STORE_PAYMENTS,
      variables: filter,
    })
    return {
      data: data.getStorePaymentMethodByStoreId.nodes,
      totalCount: data.getStorePaymentMethodByStoreId.totalCount,
    }
  }

  async deleteStorePayment(id: string) {
    return await apolloClient.mutate({
      mutation: DELETE_STORE_PAYMENT,
      variables: {id},
    })
  }

  async getStore(id: string) {
    const {data} = await apolloClient.query({
      query: GET_STORE,
      variables: {id},
    })
    return data.store.name
  }

  async updateStorePaymentMethodDefault(id: string) {
    return await apolloClient.mutate({
      mutation: UPDATE_STORE_PAYMENT_METHOD_DEFAULT,
      variables: {id},
    })
  }

  async getStoreAddress(filter: any) {
    const {data} = await apolloClient.query({
      query: GET_BILLING_ADDRESSES,
      variables: filter,
    })
    return {
      data: data.getStoreBillingAddressByStoreId.nodes,
      totalCount: data.getStoreBillingAddressByStoreId.totalCount,
    }
  }

  async createStorePayment(storePayment: any) {
    const {data, errors} = await apolloClient.mutate({
      mutation: CREATE_STORE_PAYMENT,
      variables: {storePaymentMethod: mapToCreateBillingAccount(storePayment)},
    })
    if (errors) {
      throw errors
    }
    return data.createStorePaymentMethod
  }
}

const paymentService = new PaymentService()
export default paymentService
