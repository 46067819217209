import {combineReducers} from 'redux'
import account from './accountSlice'
import payment from './PaymentSlice'

const accountReducers = combineReducers({
  account,
  payment,
})

export default accountReducers
