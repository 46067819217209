import axios from 'axios'
import {gql} from '@apollo/client'
import apolloClient from 'apollo-client'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'
import {GET_LOGGED_IN_USER} from '../query/auth'
import config from 'config'

export const GET_USER_BY_ACCESS_TOKEN_URL = '/auth/get-user'
export const LOGIN_URL = `/users/v1/auth/admin-login`
export const REGISTER_URL = `/auth/register`
export const REQUEST_PASSWORD_URL = `/auth/forgot-password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {email, password})
}

// Server should return AuthModel
export function register(email: string, firstName: string, lastName: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstName,
    lastName,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

export function getUserByToken() {
  return apolloClient.query<UserModel>({
    query: gql`
      ${GET_LOGGED_IN_USER}
    `,
  })
}
