import {RootState} from 'setup'
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {showMessage} from 'app/redux/messageSlice'
import reportsService from 'app/services/ReportsService/ReportsService'
import {setTotalCount} from 'app/redux/pagingSlice'

const initialState = {
  isFetching: false,
  filter: {
    fromDate: null,
    toDate: null,
    status: null,
    tagId: null,
  },
  filterOverTimeFee: [],
  feeReports: [],
}

export const getReports = (filter: any) => async (dispatch: any) => {
  dispatch(getTopFeeSplitup(filter))
  dispatch(setFilter({...filter, tagId: filter?.tagId || null, status: filter?.status || null}))
}

export const getTopFeeSplitup = createAsyncThunk('fee/top', async (filter: any, {dispatch}) => {
  try {
    dispatch(setIsFetching(true))
    let newFilter = {
      ...filter,
    }
    const response = await reportsService.getTopFeeReports(newFilter)
    return response
  } catch (err) {
    console.error(err)
    dispatch(
      showMessage({
        variant: 'error',
        text: 'An error occurred while getting reports',
      })
    )
  } finally {
    dispatch(setIsFetching(false))
  }
})

export const filterOverTimeFeeReports = createAsyncThunk(
  'fee/all',
  async (_filter: any, {dispatch}) => {
    try {
      dispatch(setIsFetching(true))
      const filter: any = {..._filter}
      if (!filter.offset) {
        filter.offset = 0
      }
      const {data, totalCount} = await reportsService.filterOverTimeFeeReports(filter)
      dispatch(setTotalCount(totalCount))
      return data
    } catch (err) {
      console.error(err)
      dispatch(
        showMessage({
          variant: 'error',
          text: 'An error occurred while getting reports',
        })
      )
    } finally {
      dispatch(setIsFetching(false))
    }
  }
)

const feeSlice = createSlice({
  name: 'fee',
  initialState,
  reducers: {
    setIsFetching: (state, action) => {
      state.isFetching = action.payload
    },
    setFilter: (state, action) => {
      state.filter = action.payload
    },
  },
  extraReducers: {
    [getTopFeeSplitup.fulfilled.toString()]: (state, action) => {
      state.feeReports = action.payload
    },
    [filterOverTimeFeeReports.fulfilled.toString()]: (state, action) => {
      state.filterOverTimeFee = action.payload
    },
  },
})

export const {setIsFetching, setFilter} = feeSlice.actions

export const selectIsFetching = ({reports}: RootState): boolean => reports.fee.isFetching
export const selectFeeReportsList = ({reports}: RootState): Array<any> =>
  reports.fee.filterOverTimeFee
export const selectFilter = ({reports}: RootState): any => reports.fee.filter
export const selectTopFeeReports = ({reports}: RootState): Array<any> => reports.fee.feeReports

export default feeSlice.reducer
