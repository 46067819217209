import * as yup from 'yup'

export enum feeTypes {
  minimum = 'MINIMUM',
  flat = 'FLAT',
}

type Store = {
  id?: string
  name: string
  transactionFee: number | ''
  minimumFee: number | ''
  storePlanId?: string
  feeType: feeTypes
  posTransactionFee: number | ''
  flatFee: number | ''
}

export const storeInitialState: Store = {
  name: '',
  transactionFee: '',
  minimumFee: '',
  posTransactionFee: 0,
  feeType: feeTypes.minimum,
  flatFee: '',
}

export const StoreValidationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  transactionFee: yup
    .number()
    .required('Transaction fee is required')
    .typeError('Transaction fee is required'),
  minimumFee: yup.number().when('feeType', {
    is: feeTypes.minimum,
    then: yup.number().required('Minimum SAAS Fee is required').typeError('Minimum SAAS Fee is required'),
    otherwise: yup.number().notRequired().nullable(),
  }),
  flatFee: yup.number().when('feeType', {
    is: feeTypes.flat,
    then: yup.number().required('Flat SAAS Fee is required').typeError('Flat SAAS Fee is required'),
    otherwise: yup.number().notRequired().nullable(),
  }),
})

export default Store
