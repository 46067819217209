import apolloClient from 'apollo-client'
import {GET_OVERTIME_INVOICE_REVENUE, GET_OVERTIME_STORE_CREATION} from './graphql'
import get from 'lodash/get'
import set from 'lodash/set'
import {getTimezone} from 'app/utilities'

class AnalyticsService {
  filterOverTimeStoreCreation = async (filter: any) => {
    filter.timeZone = getTimezone()
    const {data, errors} = await apolloClient.query({
      query: GET_OVERTIME_STORE_CREATION,
      variables: filter,
    })
    if (errors) {
      throw errors
    }
    return {
      data: parseIntList(data.getStoreCreations.nodes, 'totalCount'),
      totalCount: data.getStoreCreations.totalCount,
    }
  }

  filterOverTimeInvoiceRevenue = async (filter: any) => {
    const {data, errors} = await apolloClient.query({
      query: GET_OVERTIME_INVOICE_REVENUE,
      variables: filter,
    })
    if (errors) {
      throw errors
    }
    return {
      data: parseFloatList(data.getStoreInvoiceRevenue.nodes, 'revenue'),
      totalCount: data.getStoreInvoiceRevenue.totalCount,
    }
  }
}

const analyticsService = new AnalyticsService()
export default analyticsService

export const parseFloatList = (list: any[], path: string) => {
  return list.map((item) => {
    const val = parseFloat(get(item, path))
    set(item, path, val)
    return item
  })
}

export const parseIntList = (list: any[], path: string) => {
  return list.map((item) => {
    const val = parseInt(get(item, path))
    set(item, path, val)
    return item
  })
}
