import {gql} from '@apollo/client'

export const GET_SUBSCRIPTION_PLANS = gql`
  query GetSubscriptionPlans($pageSize: Int!, $offset: Int!) {
    subscriptionPlans(offset: $offset, first: $pageSize, filter: {deletedAt: {isNull: true}}) {
      nodes {
        id
        name
        description
        price
        transactionFee
        minimumFee
      }
      totalCount
    }
  }
`

export const GET_SUBSCRIPTION = gql`
  query GetSubscriptionPlan($id: UUID!) {
    subscriptionPlan(id: $id) {
      name
      description
      price
      isTrail
      posTransactionFee
      transactionFee
      feeType
      fee
    }
  }
`

export const CREATE_SUBSCRIPTION_PLAN = gql`
  mutation CreateSubscriptionPlan($subscription: SubscriptionPlanInput!) {
    createSubscriptionPlan(input: {subscriptionPlan: $subscription}) {
      clientMutationId
    }
  }
`

export const UPDATE_SUBSCRIPTION_PLAN = gql`
  mutation updateSubscriptionPlan($id: UUID!, $patch: SubscriptionPlanPatch!) {
    updateSubscriptionPlan(input: {id: $id, patch: $patch}) {
      clientMutationId
    }
  }
`

export const DELETE_SUBCRIPTION_PLAN = gql`
  mutation DeleteSubscriptionPlan($id: UUID!) {
    deleteSubscriptionPlan(input: {id: $id}) {
      clientMutationId
    }
  }
`
