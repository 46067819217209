import Service from '../Service'
import apolloClient from 'apollo-client'
import {CHECK_USER_EMAIL_EXIST, GET_USER, GET_USERS, GET_USERS_ROLES} from './graphql'

class UserService extends Service {
  constructor() {
    super('/auth/v1/')
  }
  async createUser(user: any) {
    return this.post(user, 'stores/users/admin')
  }

  async updateUser(user: any) {
    return this.post(user, 'stores/users/update/admin')
  }

  async getUsers(params: any) {
    const {data} = await apolloClient.query({
      query: GET_USERS,
      variables: params,
    })
    return {data: data.getStoreUsers.nodes, totalCount: data.getStoreUsers.totalCount}
  }
  async getUser(id: any) {
    const {data} = await apolloClient.query({
      query: GET_USER,
      variables: {
        id,
      },
    })
    return data.user
  }
  async getUsersRoles() {
    const {data} = await apolloClient.query({
      query: GET_USERS_ROLES,
    })
    return data.getStoreUserRoles.nodes
  }

  async checkUserEmail(userId: string, email: string) {
    const {data} = await apolloClient.query({
      query: CHECK_USER_EMAIL_EXIST,
      variables: {userId, email},
    })
    return data.checkUserEmailExist
  }
}

const userService = new UserService()
export default userService
