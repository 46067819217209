import {createSlice} from '@reduxjs/toolkit'
import {ToastOptions} from 'react-toastify'

export type MessageState = {
  state: boolean
  options: ToastOptions
  text: string
  variant: 'success' | 'error' | 'info' | 'warning'
}

const initialState: MessageState = {
  state: false,
  text: '',
  variant: 'info',
  options: {
    position: 'top-right',
    autoClose: 6000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  },
}

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    showMessage: (state, action) => {
      state.state = true
      state.text = action.payload.text
      state.variant = action.payload.variant
      state.options = {
        ...initialState.options,
        ...action.payload.options,
      }
    },
    hideMessage: (state, action) => {
      state.state = false
    },
  },
})

export const {hideMessage, showMessage} = messageSlice.actions

export default messageSlice.reducer
