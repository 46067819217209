import {gql} from '@apollo/client'

export const GET_ACCOUNT_TAGS = gql`
  query getAccountTags {
    storeAccountTags(orderBy: DISPLAY_ORDER_ASC) {
      nodes {
        id
        name
      }
    }
  }
`
