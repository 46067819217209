import apolloClient from 'apollo-client'
import {GET_MENUS} from './graphql'

class MenuService {
  async getMenus() {
    const {data} = await apolloClient.query({
      query: GET_MENUS,
    })
    return data.getAdminMenus.nodes
  }
}
const menuService = new MenuService()
export default menuService
