import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {hideLoader, showLoader} from 'app/redux/loaderSlice'
import {setTotalCount} from 'app/redux/pagingSlice'
import invoiceService from 'app/services/InvoiceService'
import {RootState} from 'setup'

const initialState = {
  invoiceList: [],
  filter: {
    storeId: null,
    invoiceDate: null,
    status: null,
    hasPaymentMethod: null,
    offset: 0,
    pageSize: 10,
  },
  isSubmitting: false,
}

export const getInvoice = createAsyncThunk(
  'invoices/list',
  async (filter: any, {dispatch}: any) => {
    dispatch(showLoader())
    const {data, totalCount} = await invoiceService.getSubscriptionInvoice(
      filter || initialState.filter
    )
    dispatch(hideLoader())
    dispatch(setTotalCount(totalCount))
    return data
  }
)

const invoiceSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setDate: (state, action) => {
      state.filter = {
        ...state.filter,
        offset: 0,
        invoiceDate: action.payload,
      }
    },
    setPaymentStatus: (state, action) => {
      state.filter = {
        ...state.filter,
        offset: 0,
        status: action.payload,
      }
    },
    setStoreId: (state, action) => {
      state.filter = {
        ...state.filter,
        offset: 0,
        storeId: action.payload,
      }
    },
    setSubmitting: (state, action) => {
      state.isSubmitting = action.payload
    },
  },
  extraReducers: {
    [getInvoice.fulfilled.toString()]: (state, action) => {
      state.invoiceList = action.payload
    },
  },
})
export const {setDate, setStoreId, setPaymentStatus, setSubmitting} = invoiceSlice.actions
export default invoiceSlice.reducer

export const selectInvoiceList = ({invoices}: RootState): Array<any> =>
  invoices.invoices.invoiceList

export const selectFilter = ({invoices}: RootState): any => invoices.invoices.filter

export const selectSubmitting = ({invoices}: RootState): any => invoices.invoices.isSubmitting
