import {combineReducers} from '@reduxjs/toolkit'
import message from './messageSlice'
import loader from './loaderSlice'
import dialog from './dialogSlice'
import paging from './pagingSlice'

const miscReducers = combineReducers({
  message,
  loader,
  dialog,
  paging,
})

export default miscReducers
