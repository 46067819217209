import {gql} from '@apollo/client'

export const GET_ACCOUNT_CREATION_LINK = gql`
query ($orderBy: [AccountCreationLinkRequestsOrderBy!] , $offset: Int) {
  accountCreationLinkRequests(orderBy: $orderBy, offset: $offset, first: 10) {
      nodes {
        id
        active
        createdAt
        fee
        feeType
        link
        posTransactionFee
        token
        transactionFee
      }
      totalCount
    }
  }
`

export const CREATE_ACCOUNT_CREATION_LINK = gql`
  mutation CreateAccountCreationLinkRequest($feeType: FeeTypes, $fee: BigFloat, $link: String!, $posTransactionFee: BigFloat, $token: String!, $transactionFee: BigFloat ) {
    createAccountCreationLinkRequest(
      input: {accountCreationLinkRequest: {link: $link, token: $token, active: true, posTransactionFee: $posTransactionFee, transactionFee: $transactionFee, fee: $fee, feeType: $feeType}}
    ) {
      clientMutationId
    }
  }
`

export const DELETE_ACCOUNT_CREATION_LINK = gql`
  mutation DeleteAccountCreationLinkRequest($id: UUID!) {
    deleteAccountCreationLinkRequest(input: {id: $id}) {
      clientMutationId
    }
  }
`