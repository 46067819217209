import {gql} from '@apollo/client'

export const GET_TOP_FEE_SPLIT_UP_REPORT = gql`
  query GetTopFeeSplitUpReport($fromDate: Date!, $toDate: Date!, $status: String, $tagId: UUID) {
    getTopFeeSplitUpReport(fromDate: $fromDate, toDate: $toDate, pStatus: $status, pTagId: $tagId) {
      nodes {
        storeName
        totalApplicationFee
        totalFee
      }
    }
  }
`

export const GET_FEE_SPLIT_UP_DETAIL_REPORT = gql`
  query GetFeeSplitUpDetailReport(
    $fromDate: Date!
    $toDate: Date!
    $offset: Int!
    $status: String
    $tagId: UUID
  ) {
    getFeeSplitUpDetailReport(
      fromDate: $fromDate
      toDate: $toDate
      offset: $offset
      first: 10
      pStatus: $status
      pTagId: $tagId
    ) {
      nodes {
        fee
        feeType
        posTransactionFee
        storeName
        totalApplicationFee
        totalFee
        transactionFee
      }
      totalCount
    }
  }
`
