import {RootState} from 'setup'
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {showMessage} from 'app/redux/messageSlice'
import analyticsService from 'app/services/AnalyticsService/AnalyticsService'

const initialState = {
  isFetching: false,
  filter: {
    fromDate: null,
    toDate: null,
    paymentStatus: null,
    tagId: null,
  },
  invoiceRevenue: [],
  storeCreations: [],
  storeCreationTotalCount: 0,
  invoiceRevenueTotalCount: 0,
}

export const getAnalyticReports = (filter: any) => async (dispatch: any) => {
  dispatch(filterOverTimeInvoiceRevenue(filter))
  dispatch(filterOverTimeStoreCreation(filter))
  dispatch(setFilter(filter))
}

export const filterOverTimeStoreCreation = createAsyncThunk(
  'account/creation',
  async (filter: any, {dispatch}) => {
    try {
      dispatch(setIsFetching(true))
      const {data, totalCount} = await analyticsService.filterOverTimeStoreCreation(filter)
      dispatch(setStoreCreationTotalCount(totalCount))
      return data
    } catch (err) {
      console.error(err)
      dispatch(
        showMessage({
          variant: 'error',
          text: 'An error occurred while getting reports',
        })
      )
    } finally {
      dispatch(setIsFetching(false))
    }
  }
)

export const filterOverTimeInvoiceRevenue = createAsyncThunk(
  'account/invoice_revenue',
  async (filter: any, {dispatch}) => {
    try {
      dispatch(setIsFetching(true))
      const {data, totalCount} = await analyticsService.filterOverTimeInvoiceRevenue(filter)
      dispatch(setInvoiceRevenueTotalCount(totalCount))
      return data
    } catch (err) {
      console.error(err)
      dispatch(
        showMessage({
          variant: 'error',
          text: 'An error occurred while getting reports',
        })
      )
    } finally {
      dispatch(setIsFetching(false))
    }
  }
)

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setIsFetching: (state, action) => {
      state.isFetching = action.payload
    },
    setFilter: (state, action) => {
      state.filter = action.payload
    },
    setStoreCreationTotalCount: (state, action) => {
      state.storeCreationTotalCount = action.payload
    },
    setInvoiceRevenueTotalCount: (state, action) => {
      state.invoiceRevenueTotalCount = action.payload
    },
  },
  extraReducers: {
    [filterOverTimeInvoiceRevenue.fulfilled.toString()]: (state, action) => {
      state.invoiceRevenue = action.payload
    },
    [filterOverTimeStoreCreation.fulfilled.toString()]: (state, action) => {
      state.storeCreations = action.payload
    },
  },
})

export const {setIsFetching, setFilter, setStoreCreationTotalCount, setInvoiceRevenueTotalCount} =
  accountSlice.actions

export const selectIsFetching = ({analytics}: RootState): boolean => analytics.account.isFetching
export const selectStoreCreationsList = ({analytics}: RootState): Array<any> =>
  analytics.account.storeCreations
export const selectDateFilter = ({analytics}: RootState): any => analytics.account.filter
export const selectInvoiceRevenueList = ({analytics}: RootState): Array<any> =>
  analytics.account.invoiceRevenue
export const selectStoreCreationsTotalCount = ({analytics}: RootState): number =>
  analytics.account.storeCreationTotalCount
export const selectInvoiceRevenueTotalCount = ({analytics}: RootState): number =>
  analytics.account.invoiceRevenueTotalCount

export default accountSlice.reducer
