import apolloClient from 'apollo-client'
import {eventNames} from 'app/constant'
import Service from '../Service'
import {GET_STORE} from './graphql'
import {toYMD, startOfMonth} from 'app/format/date'

class InvoiceService extends Service {
  constructor() {
    super('')
  }
  async getSubscriptionInvoice(filter: any) {
    if (!filter.invoiceDate) {
      filter.invoiceDate = toYMD(startOfMonth())
    }
    return await this.post(filter, 'billing/v1/payment/list/invoice')
  }
  async getStores() {
    const {data} = await apolloClient.query({
      query: GET_STORE,
    })
    return {data: data.getStores.nodes, totalCount: data.getStores.totalCount}
  }

  async processPayment(message: any) {
    const event = {
      name: eventNames.invoicePayment,
      message,
    }
    return await this.post(event, 'v1/events/publish')
  }
}

const invoiceService = new InvoiceService()
export default invoiceService
