import {RootState} from 'setup'
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {showMessage} from 'app/redux/messageSlice'
import accountCreationService from 'app/services/AccountCreationService/AccountCreationService'
import {mapToCreateLink} from '../models/helper'
import {setTotalCount} from 'app/redux/pagingSlice'

const initialState = {
  isFetching: false,
  isSubmitting: false,
  filter: {
    orderBy: 'CREATED_AT_ASC',
    offset: 0,
  },
  linkList: [],
}

export const getLinkList = createAsyncThunk(
  'accountCreationLink/list',
  async (filter: any, {dispatch}) => {
    try {
      dispatch(setIsFetching(true))
      const {data, totalCount} = await accountCreationService.getAccountCreationLinkList(filter)
      dispatch(setTotalCount(totalCount))
      return data
    } catch (err) {
      console.error(err)
      dispatch(
        showMessage({
          variant: 'error',
          text: 'An error occurred while getting link list',
        })
      )
    } finally {
      dispatch(setIsFetching(false))
    }
  }
)

export const createAccountCreationLink = createAsyncThunk(
  'account/creationLink',
  async (params: any, {dispatch}) => {
    const {value, history} = params
    try {
      dispatch(setIsSubmitting(true))
      await accountCreationService.createAccountCreationLinkList(mapToCreateLink(value))
      history.push('/creationLink')
    } catch (err) {
      console.error(err)
      dispatch(
        showMessage({
          variant: 'error',
          text: 'An error occurred while creating link',
        })
      )
    } finally {
      dispatch(setIsSubmitting(false))
    }
  }
)

export const deleteAccountCreationLink = createAsyncThunk(
  'account/deleteLink',
  async (params: any, {dispatch}) => {
    const {id, filter} = params
    try {
      dispatch(setIsSubmitting(true))
      await accountCreationService.deleteAccountCreationLinkList(id)
      dispatch(
        showMessage({
          variant: 'success',
          text: 'Link deleted successfully',
        })
      )
      dispatch(getLinkList(filter))
    } catch (err) {
      console.error(err)
      dispatch(
        showMessage({
          variant: 'error',
          text: 'An error occurred while deleting link',
        })
      )
    } finally {
      dispatch(setIsSubmitting(false))
    }
  }
)

const accountCreationSlice = createSlice({
  name: 'accountCreation',
  initialState,
  reducers: {
    setIsFetching: (state, action) => {
      state.isFetching = action.payload
    },
    setIsSubmitting: (state, action) => {
      state.isSubmitting = action.payload
    },
    setFilter: (state, action) => {
      state.filter = action.payload
    },
  },
  extraReducers: {
    [getLinkList.fulfilled.toString()]: (state, action) => {
      state.linkList = action.payload
    },
  },
})

export const {setIsFetching, setIsSubmitting, setFilter} = accountCreationSlice.actions

export const selectIsFetching = ({accountCreations}: RootState): boolean =>
  accountCreations.accountCreation.isFetching
export const selectIsSubmitting = ({accountCreations}: RootState): boolean =>
  accountCreations.accountCreation.isSubmitting
export const selectLinkList = ({accountCreations}: RootState): Array<any> =>
  accountCreations.accountCreation.linkList

export const selectFilter = ({accountCreations}: RootState): any =>
  accountCreations.accountCreation.filter

export default accountCreationSlice.reducer
