import {ApolloClient, InMemoryCache, createHttpLink} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'
const prodBaseURL = 'https://graphql.corksy.io/graphql'
const stagBaseURL = '/graphql'
const devBaseURL = 'http://localhost:8080/graphql'
const demoBaseUrl = '/graphql'

let graphQL = config()

function config() {
  if (window.location.href.includes('superadmin.corksy.io')) {
    return prodBaseURL
  } else if (window.location.href.includes('localhost')) {
    return devBaseURL
  } else if (window.location.href.includes('superadmin.demo.corksy.io')) {
    return demoBaseUrl
  } else {
    return stagBaseURL
  }
}

const httpLink = createHttpLink({
  uri: graphQL,
})

const authLink = setContext((_, {headers}) => {
  const token = sessionStorage.getItem('accessToken')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

export default apolloClient
