import Service from '../Service'
import apolloClient from 'apollo-client'
import {CREATE_STORE_PLAN_FEATURE, GET_PLAN_FEATURES, GET_SORES, GET_SUBSCRIPTION_PLAN_FEATURES} from './graphql'

class PlanFeatureService extends Service {
  constructor() {
    super('')
  }
  async getStores(param: any) {
    const {data} = await apolloClient.query({
      query: GET_SORES,
      variables: param,
    })
    return {
      data: data.getNotSubscribeFeatureStores.nodes,
      totalCount: data.getNotSubscribeFeatureStores.totalCount,
    }
  }
  async getPlanFeatures() {
    const {data} = await apolloClient.query({
      query: GET_PLAN_FEATURES,
    })
    return data.getPlanTypeFeature.nodes
  }

  async createPlanFeature(params:any) {
    return await apolloClient.mutate({
      mutation: CREATE_STORE_PLAN_FEATURE,
      variables:params
    })
    
  }
  async getStoreSubscriptionFeatures(storeId: any) {
    const {data} = await apolloClient.query({
      query: GET_SUBSCRIPTION_PLAN_FEATURES,
      variables: {storeId},
    })
    return {
      data: data.getStoreSubscriptionItems.nodes,
      totalCount: data.getStoreSubscriptionItems.totalCount,
    }
  }
}

const planFeatureService = new PlanFeatureService()
export default planFeatureService
