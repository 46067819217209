import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {hideLoader, showLoader} from 'app/redux/loaderSlice'
import {setTotalCount} from 'app/redux/pagingSlice'
import subscriptionPlanService from 'app/services/SubscriptionPlanService'

export const getSubscriptionPlans = createAsyncThunk(
  'subscriptionPlan/list',
  async ({offset, pageSize}: any, {dispatch}: any) => {
    const params = {
      pageSize,
      offset,
    }
    dispatch(showLoader())
    dispatch(setFetching(true))
    const {data, totalCount} = await subscriptionPlanService.getSubscriptionPlans(params)
    dispatch(setTotalCount(totalCount))
    dispatch(hideLoader())
    return data
  }
)
export const subscriptionPlanDetail = createAsyncThunk(
  'subscriptionPlan/package',
  async (id: string, {dispatch}: any) => {
    dispatch(showLoader())
    const response = await subscriptionPlanService.subscriptionPlan(id)
    dispatch(hideLoader())
    return response
  }
)

const initialState = {
  subscriptionPlans: [],
  isFetching: false,
  plan: {},
  offset: 0,
}

const subscriptionSlice = createSlice({
  name: 'subscriprionPlan',
  initialState,
  reducers: {
    setFetching: (state, action) => {
      state.isFetching = action.payload
    },
    resetSubscriptionPlan: (state) => {
      return initialState
    },
    setOffset: (state, action) => {
      state.offset = action.payload
    },
  },
  extraReducers: {
    [getSubscriptionPlans.fulfilled.toString()]: (state, action) => {
      state.subscriptionPlans = action.payload
      state.isFetching = false
    },
    [subscriptionPlanDetail.fulfilled.toString()]: (state, action) => {
      state.plan = action.payload
    },
  },
})

export const {setFetching, resetSubscriptionPlan, setOffset} = subscriptionSlice.actions
export default subscriptionSlice.reducer
