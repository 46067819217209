import {gql} from '@apollo/client'

export const GET_MENUS = gql`
  query GetMenus {
    getAdminMenus {
      nodes {
        code
        displayOrder
        icon
        name
        id
        showInNavbar
        url
      }
    }
  }
 ` 