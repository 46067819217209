import {gql} from '@apollo/client'

export const GET_PLAN_FEATURES = gql`
  query GetPlanFeature {
    getPlanTypeFeature {
      nodes {
        id
        name
        displayOrder
      }
      totalCount
    }
  }
`

export const GET_SORES = gql`
  query GetStores($offset: Int!) {
    getNotSubscribeFeatureStores(offset: $offset, first: 10) {
      nodes {
        id
        name
      }
      totalCount
    }
  }
`
export const CREATE_STORE_PLAN_FEATURE = gql`
  mutation CreateStorePremiumFeatures($storeId: UUID!, $planFeatureIds: [UUID]!) {
    createStorePremiumFeatures(input: {pStoreId: $storeId, pPlanFeatureIds: $planFeatureIds}) {
      clientMutationId
    }
  }
`
export const GET_SUBSCRIPTION_PLAN_FEATURES = gql`
  query GetStoreSubscriptionLineItems($storeId: UUID) {
    getStoreSubscriptionItems(pStoreId: $storeId) {
      nodes {
        id
        planFeatureId
      }
      totalCount
    }
  }
`
