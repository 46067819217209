export function FallbackView() {
  return (
    // <div className='inner-splash-screen'>
    //   <img src={toAbsoluteUrl('/media/logos/logo.svg')} alt='Start logo' />
    //   <span>Loading ...</span>
    // </div>
    <div className='w-100 vh-75 d-flex justify-content-center align-items-center'>
      <label>
        <span className='indicator-progress d-block'>
          <span className='spinner-border align-middle ms-5 text-info'></span>
        </span>
        Loading...
      </label>
    </div>
  )
}
