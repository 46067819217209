import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {hideLoader, showLoader} from 'app/redux/loaderSlice'
import {} from 'app/redux/messageSlice'
import {setTotalCount} from 'app/redux/pagingSlice'
import userService from 'app/services/userService'

export const getUsers = createAsyncThunk('user/list', async (filter: any, {dispatch}: any) => {
  dispatch(showLoader())
  const {data, totalCount} = await userService.getUsers(filter)
  dispatch(hideLoader())
  dispatch(setTotalCount(totalCount))
  return data
})

export const getUser = createAsyncThunk('user/fetch', async (id: any, {dispatch}: any) => {
  try {
    dispatch(setFetching(true))
    const data = await userService.getUser(id)
    return data
  } catch (error) {
    console.error(error)
  } finally {
    dispatch(setFetching(false))
  }
})

const initialState = {
  usersList: [],
  user: {},
  filter: {
    user: null,
    offset: 0,
    pageSize: 10,
    orderBy: 'NAME_ASC',
  },
  isSubmitting: false,
  isFetching: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = {...state.filter, ...action.payload}
    },
    setSubmitting: (state, action) => {
      state.isSubmitting = action.payload
    },
    setFetching: (state, action) => {
      state.isFetching = action.payload
    },
    resetUserState: () => initialState,
  },
  extraReducers: {
    [getUsers.fulfilled.toString()]: (state, action) => {
      state.usersList = action.payload
    },
    [getUser.fulfilled.toString()]: (state, action) => {
      state.user = action.payload
    },
  },
})
export const {setFilter, setSubmitting, setFetching, resetUserState} = userSlice.actions
export default userSlice.reducer
