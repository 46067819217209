import filter from 'lodash/filter'
import Store, {feeTypes} from './Store'

export const mapToStore = (data: any) => {
  const store: Store = {
    id: data.id,
    name: data.name,
    transactionFee: data.transactionFee,
    storePlanId: data.storePlanId,
    feeType: data.feeType,
    posTransactionFee: data.posTransactionFee,
    flatFee: data.feeType === feeTypes.flat ? parseFloat(data.fee) : '',
    minimumFee: data.feeType === feeTypes.minimum ? parseFloat(data.fee) : '',
  }
  return store
}
export const mapToUpdateStorePlan = (data: Store) => {
  const {storePlanId} = data

  const patch = {
    feeType: data.feeType,
    fee: data.minimumFee,
    transactionFee: data.transactionFee,
    posTransactionFee: data.posTransactionFee,
  }

  if (data.feeType === feeTypes.minimum) {
    patch.fee = data.minimumFee
  } else {
    patch.fee = data.flatFee
  }

  return {
    id: storePlanId,
    patch,
  }
}

export const filterNewItems = (items: any[]) => {
  return filter(items, (item: any) => item.isLocal).map((feature: any) => feature.id)
}
