import apolloClient from 'apollo-client'
import {mapToUpdateSubscriptionPlan} from 'app/features/subscriptionPlan/models/helper'
import SubscriptionPlan from 'app/features/subscriptionPlan/models/plan'
import {
  CREATE_SUBSCRIPTION_PLAN,
  DELETE_SUBCRIPTION_PLAN,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_PLANS,
  UPDATE_SUBSCRIPTION_PLAN,
} from './graphql'

class SubscriptionPlanService {
  async getSubscriptionPlans(params: any) {
    const {data} = await apolloClient.query({
      query: GET_SUBSCRIPTION_PLANS,
      variables: params,
    })
    return {data: data.subscriptionPlans.nodes, totalCount: data.subscriptionPlans.totalCount}
  }

  async subscriptionPlan(id: any) {
    const {data} = await apolloClient.query({
      query: GET_SUBSCRIPTION,
      variables: {id},
    })
    return data.subscriptionPlan
  }

  async create(subscription: any) {
    return await apolloClient.mutate({
      mutation: CREATE_SUBSCRIPTION_PLAN,
      variables: {subscription},
    })
  }
  async update(id: string, subscription: SubscriptionPlan) {
    return await apolloClient.mutate({
      mutation: UPDATE_SUBSCRIPTION_PLAN,
      variables: mapToUpdateSubscriptionPlan(id, subscription),
    })
  }

  async delete(id: string) {
    return await apolloClient.mutate({
      mutation: DELETE_SUBCRIPTION_PLAN,
      variables: {id},
    })
  }
}
export default new SubscriptionPlanService()
