import moment from 'moment'

export const formatToMDY = (date: string) => {
  return formatDate(date, 'MM-DD-YYYY')
}

export const formatToDateString2 = (date: string) => {
  if (date) {
    return formatDate(date, 'MMM DD, YYYY')
  }
  return '-'
}

export const formatToShortDateString = (date: string) => {
  return formatDate(date, 'MMM DD [at] h:mm a')
}

export const formatDate = (date: any, format: string) => {
  return moment(date).format(format)
}

export const formatTime = (date: any) => {
  return moment(date, 'hh:mm A').format('hh:mm A')
}

export const formatLongTime = (date: any) => {
  return moment(date, 'hh:mm A').format('hh:mm A')
}

export const toMMDD = (date: any) => {
  return moment(date).format('MMM DD')
}
export const toYMD = (date?: any) => {
  if (!date) {
    return moment().format('YYYY-MM-DD')
  }
  return date.format('YYYY-MM-DD')
}
export const toDMY = (date: any) => {
  return moment(date).format('Do MMMM, YYYY')
}
export const toDay = () => {
  return moment()
}

export const startOfMonth = () => {
  return moment().startOf('month')
}

export const endOfMonth = () => {
  return moment().endOf('month')
}

export const lastNMonth = (date: any, month: number) => {
  return moment(date).subtract(month, 'month')
}

export const toYYYYMMDD = (date: any) => {
  return moment(date).format('YYYY-MM-DD')
}

export const lastNYear = (date: any, year: number) => {
  return moment(date).subtract(year, 'year')
}

export const firstNYear = (date: any, year: number) => {
  return moment(date).add(year, 'year')
}

export const lastQuarterEnd = () => {
  return moment().subtract(1, 'quarter').endOf('quarter')
}

export const lastQuarterStart = () => {
  return moment().subtract(1, 'quarter').startOf('quarter')
}

export const currentQuarterStart = () => {
  const today = moment()
  const quarter = Math.ceil(today.month() / 3)
  return moment().quarter(quarter).startOf('quarter')
}

export const currentQuarterEnd = () => {
  const today = moment()
  const quarter = Math.ceil(today.month() / 3)
  return moment().quarter(quarter).endOf('quarter')
}
