import {gql} from '@apollo/client'

export const GET_USERS = gql`
  query GetStoreUsers($user: String, $offset: Int!, $orderBy: String) {
    getStoreUsers(offset: $offset, first: 10, searchText: $user, orderBy: $orderBy) {
      nodes {
        id
        email
        firstName
        lastName
        phone
        role {
          displayName
        }
      }
      totalCount
    }
  }
`

export const GET_USER = gql`
  query GetUser($id: UUID!) {
    user(id: $id) {
      id
      email
      firstName
      lastName
      phone
      pin
      roleId
      storeSelectionType
      role {
        id
        name
        displayName
        roleType
      }
      usersStores {
        nodes {
          storeId
        }
      }
    }
  }
`

export const GET_USERS_ROLES = gql`
  query GetStoreUsersRoles {
    getStoreUserRoles {
      nodes {
        id
        name
        displayName
        roleType
      }
    }
  }
`

export const CHECK_USER_EMAIL_EXIST = gql`
  query CheckUserEmailExist($email: String!, $userId: UUID) {
    checkUserEmailExist(pEmail: $email, userId: $userId)
  }
`
