import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit'
import {hideLoader, showLoader} from 'app/redux/loaderSlice'
import {} from 'app/redux/messageSlice'
import {setTotalCount} from 'app/redux/pagingSlice'
import planFeatureService from 'app/services/PlanFeatureService/PlanFeatureService'

export const getStores = createAsyncThunk('store/list', async (param: any, {dispatch}: any) => {
  dispatch(showLoader())
  const {data, totalCount} = await planFeatureService.getStores(param)
  dispatch(hideLoader())
  dispatch(setTotalCount(totalCount))
  return data
})

export const getPlanFeatures = createAsyncThunk('plan/features', async (_, {dispatch}: any) => {
  try {
    dispatch(setFetching(true))
    const data = await planFeatureService.getPlanFeatures()
    return data
  } catch (error) {
    console.error(error)
  } finally {
    dispatch(setFetching(false))
  }
})

const initialState = {
  planFeatures: [],
  selectedFeature: [],
  selectedStores: [],
  user: {},
  filter: {
    offset: 0,
    pageSize: 10,
    orderBy: 'NAME_ASC',
  },
  isSubmitting: false,
  isFetching: false,
}

const entityAdapter = createEntityAdapter({})

export const {selectAll: selectPlanFeatures, selectById: selectPlanFeaturesById} =
  entityAdapter.getSelectors(({settings}: any) => settings.planFeature)

const featureEnableSlice = createSlice({
  name: 'planFeatureEnable',
  initialState: entityAdapter.getInitialState(initialState),
  reducers: {
    setFilter: (state, action) => {
      state.filter = {...state.filter, ...action.payload}
    },
    setSubmitting: (state, action) => {
      state.isSubmitting = action.payload
    },
    setFetching: (state, action) => {
      state.isFetching = action.payload
    },
    setSelectedFeature: (state, action) => {
      state.selectedFeature = action.payload
    },
    setSelectedStores: (state, action) => {
      state.selectedStores = action.payload
    },
    resetPlanFeatures: () => {
      return entityAdapter.getInitialState(initialState)
    },

    updatePlanFeatures: entityAdapter.updateOne,
  },
  extraReducers: {
    [getPlanFeatures.fulfilled.toString()]: entityAdapter.setAll,
  },
})
export const {
  setFilter,
  setSubmitting,
  setFetching,
  setSelectedFeature,
  setSelectedStores,
  updatePlanFeatures,
  resetPlanFeatures,
} = featureEnableSlice.actions
export default featureEnableSlice.reducer
