/* eslint-disable react/jsx-no-target-blank */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
// import {KTSVG} from '../../../helpers'
import {AsideMenuItem} from './AsideMenuItem'
import menuService from 'app/services/MenuService'
import {useLayout} from '_metronic/layout/core'
import {orderBy} from 'lodash'

export function AsideMenuMain() {
  const intl = useIntl()
  const {setMenus, menus} = useLayout()

  useEffect(() => {
    const fetch = async () => {
      const data = await menuService.getMenus()
      const menus = [
        ...data,
        // {
        //   code: 'migration',
        //   displayOrder: 8,
        //   icon: '/media/icons/duotune/general/link.svg',
        //   name: 'Migration',
        //   id: 'a541b963-8abb-45cc-abed-25590c51a508',
        //   showInNavbar: false,
        //   url: '/migration',
        // },

        // {
        //   code: 'ssl',
        //   displayOrder: 8,
        //   icon: '/media/icons/duotune/general/link.svg',
        //   name: 'SSL',
        //   id: '69bfd2f0-94ab-4474-82d6-d4c4cdbf7c32',
        //   showInNavbar: true,
        //   url: '/ssl',
        // },
      ]
      setMenus(menus)
    }
    fetch()
  }, [setMenus])

  return (
    <>
      {menus.length > 0 &&
        orderBy(menus, 'displayOrder').map((menu: any, index: any) => {
          return (
            <AsideMenuItem
              key={index}
              id={menu.id}
              to={menu.url}
              icon={menu.icon}
              title={menu.name}
              fontIcon='bi bi-people'
            />
          )
        })}
    </>
  )
}
