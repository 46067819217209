//@ts-nocheck
import ReactDOM from 'react-dom'
import {PersistGate} from 'redux-persist/integration/react'
import {Provider} from 'react-redux'
import * as _redux from './setup'
import store, {persistor} from './setup/redux/Store'
import {ApolloProvider} from '@apollo/client'
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {App} from './app/App'
import apolloClient from './apollo-client'
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
import 'react-toastify/dist/ReactToastify.css'
import 'flatpickr/dist/flatpickr.css'
import '@yaireo/tagify/dist/tagify.css'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import './_metronic/assets/sass/plugins.scss'

const {PUBLIC_URL} = process.env

_redux.setupAxios(axios, store)

Chart.register(...registerables)

ReactDOM.render(
  <MetronicI18nProvider>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
        <ApolloProvider client={apolloClient}>
          <App basename={PUBLIC_URL} />
        </ApolloProvider>
      </PersistGate>
    </Provider>
  </MetronicI18nProvider>,
  document.getElementById('root')
)
