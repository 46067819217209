import React from 'react'
import {Modal} from 'react-bootstrap-v5'

type Props = {
  show: boolean
  handleClose: () => void
}

const SearchModal: React.FC<Props> = ({show, handleClose}) => {
  return (
    <Modal
      className='bg-white'
      id='kt_header_search_modal'
      aria-hidden='true'
      dialogClassName='modal-fullscreen h-auto'
      show={show}
    ></Modal>
  )
}

export {SearchModal}
