import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {hideLoader, showLoader} from 'app/redux/loaderSlice'
import { setTotalCount } from 'app/redux/pagingSlice'
import ClientAccount from 'app/services/ClientAcounting/ClientAccountService'

export const getClientAccount = createAsyncThunk(
  `client/account`,
  async (filter: any, {dispatch}: any) => {
    dispatch(showLoader())
    const {data,totalCount} = await ClientAccount.getClientAccounting(filter)
    dispatch(hideLoader())
    dispatch(setTotalCount(totalCount))
    return data
  }

)

const initialState = {
  clientAccountList: [],
  filter: {
    client: '',
    offset: 0,
    pageSize: 10,
    orderBy: 'NAME_ASC',
  },
  isSubmitting: false,
  isFetching: false,
}

const clientAccountSlice = createSlice({
  name: 'clientAccounts',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = {...state.filter, ...action.payload}
    },
    setSubmitting: (state, action) => {
      state.isSubmitting = action.payload
    },
    setFetching: (state, action) => {
      state.isFetching = action.payload
    },
  },
  extraReducers: {
    [getClientAccount.fulfilled.toString()]: (state, action) => {
      state.clientAccountList = action.payload
    },
  },
})
export const {setFilter, setSubmitting, setFetching} = clientAccountSlice.actions
export default clientAccountSlice.reducer
