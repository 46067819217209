import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {hideLoader} from 'app/redux/loaderSlice'
import {setTotalCount} from 'app/redux/pagingSlice'
import paymentService from 'app/services/PaymentSerive/PaymentService'
import orderBy from 'lodash/orderBy'

export const getStorePayments = createAsyncThunk(
  'payment/list',
  async (filter: any, {dispatch}) => {
    dispatch(setIsFetching(true))
    const {data, totalCount} = await paymentService.getStorePayments(filter)
    dispatch(setTotalCount(totalCount))
    dispatch(setIsFetching(false))
    return orderBy(data, 'isDefault', 'desc')
  }
)

export const getStoreAddresses = createAsyncThunk(
  'payment/addressList',
  async (filter: any, {dispatch}) => {
    if (!filter.offset) {
      filter.offset = 0
    }
    dispatch(setIsFetching(true))
    const {data, totalCount} = await paymentService.getStoreAddress(filter)
    dispatch(setTotalCount(totalCount))
    dispatch(setIsFetching(false))
    dispatch(hideLoader())
    return data
  }
)

const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    storePaymentsList: [],
    storeBillingAddressList: [],
    isFetching: false,
    filter: {
      storeId: '',
      pageSize: 10,
      offset: 0,
    },
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload
    },
    setIsFetching: (state, action) => {
      state.isFetching = action.payload
    },
  },
  extraReducers: {
    [getStorePayments.fulfilled.toString()]: (state, action) => {
      state.storePaymentsList = action.payload
    },
    [getStoreAddresses.fulfilled.toString()]: (state, action) => {
      state.storeBillingAddressList = action.payload
    },
  },
})

export const {setFilter, setIsFetching} = paymentSlice.actions

export default paymentSlice.reducer
