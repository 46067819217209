import apolloClient from 'apollo-client'
import {GET_ACCOUNTS} from 'app/features/accounts/graphql/accounts'
import {GET_STORE, UPDATE_STORE, UPDATE_STORE_PLAN, UPDATE_STORE_TAG} from 'app/features/accounts/graphql/account'
import {GET_ACCOUNT_TAGS} from 'app/features/accounts/graphql/accountTag'
import {mapToUpdateStorePlan} from 'app/features/accounts/models/helper'

class AccountService {
  async getAccounts(params: any) {
    const {data} = await apolloClient.query({
      query: GET_ACCOUNTS,
      variables: params,
    })
    return {data: data.getStores.nodes, totalCount: data.getStores.totalCount}
  }

  async getAccountsTags() {
    const {data} = await apolloClient.query({
      query: GET_ACCOUNT_TAGS,
    })
    return data.storeAccountTags.nodes
  }

  async account(id: any) {
    const {data} = await apolloClient.query({
      query: GET_STORE,
      variables: {id},
    })
    return data.getStores.nodes
  }

  async updateStorePlan(data: any) {
    return await apolloClient.mutate({
      mutation: UPDATE_STORE_PLAN,
      variables: mapToUpdateStorePlan(data),
    })
  }

  async updateStore(id: string, data: any) {
    return await apolloClient.mutate({
      mutation: UPDATE_STORE,
      variables: {id, patch: data},
    })
  }
  
  async updateStoreTag(id: string, data: any) {
    return await apolloClient.mutate({
      mutation: UPDATE_STORE_TAG,
      variables: {id, patch: data},
    })
  }

  async _updateStorePlan(id: string, data: any) {
    return await apolloClient.mutate({
      mutation: UPDATE_STORE_PLAN,
      variables: {
        id,
        patch: data,
      },
    })
  }
}

const accountService = new AccountService()
export default accountService
