import AddressModel from "app/features/accounts/models/AddressModel"
import PaymentModel from "app/features/accounts/models/PaymentModel"


export const mapToCreateBillingAccount = (model: any) => {
  const {address, payment, addressId, storeId} = model
  if (!addressId) {
    return {
      card: mapToCreatePayment(payment),
      address: mapToCreateAddress(address),
      storeId:storeId
    }
  } else {
    return {
      addressId: addressId,
      card: mapToCreatePayment(payment),
      storeId:storeId
    }
  }
}

export const mapToCreateAddress = (data: AddressModel) => {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    addressLine1: data.addressLine1,
    addressLine2: data.addressLine2,
    city: data.city,
    stateId: data.stateId,
    countryId: data.countryId,
    zipCode: data.zipCode,
  }
}

export const mapToCreatePayment = (data: PaymentModel) => {
  return {
    number: data.number,
    cvv: data.cvv,
    expMonth: data.expMonth,
    expYear: data.expYear,
  }
}
