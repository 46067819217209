import apolloClient from 'apollo-client'
import {GET_CLIENT_ACCOUNTING, UPDATE_TAG_NAME} from 'app/features/clientAccounting/graphql/clientAccounting'

class ClientAccount {
  async getClientAccounting(params: any) {
    const {data, errors} = await apolloClient.query({
      query: GET_CLIENT_ACCOUNTING,
      variables: params,
    })
    if (errors) {
      throw errors
    }
    return {
      data: data.getClientAccounting.nodes,
      totalCount: data.getClientAccounting.totalCount,
    }
  }

  async updateTagClientAccounting(id: string, tagName: string) {
    try {
      const { data } = await apolloClient.mutate({
        mutation: UPDATE_TAG_NAME,
        variables: { id, tagName }
      });
      return data;
    } catch (error) {
      throw new Error(`Error updating tag: ${error}`);
    }
  }
}

const clientAccount = new ClientAccount()
export default clientAccount
