import {gql} from '@apollo/client'

export const GET_ACCOUNTS = gql`
  query GetAccounts(
    $client: String
    $offset: Int!
    $pageSize: Int!
    $orderBy: String
    $tagId: UUID
  ) {
    getStores(
      offset: $offset
      first: $pageSize
      orderBy: $orderBy
      pTagId: $tagId
      searchText: $client
    ) {
      nodes {
        adminLoginId
        companyName
        domain
        email
        id
        name
        phone
        planName
        transactionFee
        posTransactionFee
        fee
        feeType
        createdAt
        storePlanId
        subscriptionStatus
        pendingInvoices
        tagId
        tagName
        setupGuide
        paymentConnection
      }
      totalCount
    }
  }
`
