import {gql} from '@apollo/client'

export const GET_STORE = gql`
  query GetStore {
    getStores {
      nodes {
        id
        name
      }
      totalCount
    }
  }
`
export const GET_SUBSCRIPTION_INVOICES = gql`
  query findInvoices(
    $storeId: UUID
    $invoiceDate: Date
    $offset: Int!
    $pageSize: Int!
    $status: PaymentStatusTypes
    $hasPaymentMethod: Boolean
  ) {
    findInvoices(
      pStoreId: $storeId
      pInvoiceDate: $invoiceDate
      pStatus: $status
      pHasPaymentMethod: $hasPaymentMethod
      offset: $offset
      first: $pageSize
    ) {
      nodes {
        id
        hasPaymentMethod
        invoiceDate
        invoiceNumber
        periodEnd
        periodStart
        status
        paymentStatus
        storeName
        storeId
        fee
        totalApplicationFee
        subtotal
        total
      }
      totalCount
    }
  }
`
