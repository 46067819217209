import { feeTypes } from 'app/features/accounts/models/Store'
import {v4 as uuid} from 'uuid'
import config from 'config'

export const mapToCreateLink =(value:any)=>{
    const token = uuid().toString()
    var fee
    if (value.feeType === feeTypes.minimum) {
        fee = value.minimumFee
    } else {
        fee = value.flatFee
    }

    return {
        token,
        link:`${config.CLIENT_APP_DOMAIN}/store/create/${token}`,
        feeType: value.feeType,
        fee: parseFloat(fee),
        transactionFee: parseFloat(value.transactionFee),
        posTransactionFee: parseFloat(value.posTransactionFee)
    }
}