import {feeTypes} from 'app/features/accounts/models/Store'
import SubscriptionPlan from './plan'

export const mapToSubscriptionPlan = (data: any) => {
  const subscription: SubscriptionPlan = {
    id: data.id,
    name: data.name,
    description: data.description,
    price: data.price || 0,
    isTrail: data.isTrail || false,
    transactionFee: data.transactionFee,
    feeType: data.feeType,
    posTransactionFee: data.posTransactionFee,
    flatFee: data.feeType === feeTypes.flat ? parseFloat(data.fee) : '',
    minimumFee: data.feeType === feeTypes.minimum ? parseFloat(data.fee) : '',
  }
  return subscription
}
export const mapToUpdateSubscriptionPlan = (id: string, subscription: SubscriptionPlan) => {
  return {
    id,
    patch: subscription,
  }
}
