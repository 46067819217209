import {gql} from '@apollo/client'

export const GET_CLIENT_ACCOUNTING = gql`
  query GetClientAccounting($client: String, $orderBy: String, $offset: Int!, $pageSize: Int!) {
    getClientAccounting(
      searchText: $client
      pOrderBy: $orderBy
      offset: $offset
      first: $pageSize
    ) {
      nodes {
        id
        storeName
        companyName
        domain
        email
        phone
        fee
        totalCountInvoice
        transactionFee
        feeType
        createdAt
        tagId
        tagName
        creditCardOnFile
        storePlanId
        totalInvoiceAmount
        posTransactionFee
      }
      totalCount
    }
  }
`
export const UPDATE_TAG_NAME = gql`
  mutation UpdateTagClientAccount($id: UUID!, $tagName: String!) {
    updateTagClientAccounting(input: {pClientId: $id, pTagName: $tagName}) {
      clientMutationId
    }
  }
`
