import { gql } from "@apollo/client";

export const GET_STORE_PAYMENTS = gql`
query StorePaymentMethods($pageSize: Int!, $offset: Int!, $storeId: UUID) {
    getStorePaymentMethodByStoreId(
      pStoreId: $storeId
      first: $pageSize
      offset: $offset
    ) {
      nodes {
        id
        isDefault
        card {
          expYear
          expMonth
          last4
          brand
        }
        addressId
      }
      totalCount
    }
  }
`

export const DELETE_STORE_PAYMENT = gql`
  mutation DeleteStorePayment($id: UUID!) {
    deleteStorePaymentMethod(input: {id: $id}) {
      clientMutationId
    }
  }
`

export const UPDATE_STORE_PAYMENT_METHOD_DEFAULT = gql`
  mutation UpdateStorePaymentMethodDefault($id: UUID!) {
    updateStorePaymentMethodDefault(input: {paymentMethodId: $id}) {
      clientMutationId
    }
  }
`
export const GET_BILLING_ADDRESSES = gql`
  query StoreBillingAddress($pageSize: Int!, $offset: Int!, $storeId: UUID) {
    getStoreBillingAddressByStoreId(pStoreId: $storeId, first: $pageSize, offset: $offset) {
      totalCount
      nodes {
        addressLine1
        addressLine2
        firstName
        lastName
        id
        stateId
        zipCode
        countryId
      }
    }
  }
`

export const CREATE_STORE_PAYMENT = gql`
  mutation CreateStorePaymentMethod($storePaymentMethod: StoreCardPaymentMethodInput!) {
    createStorePaymentMethod(input: $storePaymentMethod) {
      paymentMethodId
      errors
      status
    }
  }
`

export const GET_STORE = gql`
query getStore($id: UUID!){
  store(id:$id){
    name
  }
}
`