import {FC} from 'react'
import clsx from 'clsx'

type Props = {
  show: boolean
}

const Loader: FC<Props> = ({show}) => {
  return (
    <div
      className={clsx(
        'splash-screen d-flex flex-column align-items-center justify-content-center p-24 bg-transparent z-index-1080',
        !show && 'd-none'
      )}
    >
      <span className='indicator-progress' style={{display: 'block'}}>
        <span className='spinner-border align-middle ms-5 text-info'></span>
      </span>
    </div>
  )
}

export default Loader
