import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getClientAccount} from 'app/features/clientAccounting/redux/clientAccountSlice'
import {hideLoader, showLoader} from 'app/redux/loaderSlice'
import {showMessage} from 'app/redux/messageSlice'
import {setTotalCount} from 'app/redux/pagingSlice'
import AccountService from 'app/services/AccountService'

export const getAccounts = createAsyncThunk(
  'account/list',
  async (filter: any, {dispatch}: any) => {
    dispatch(showLoader())
    const {data, totalCount} = await AccountService.getAccounts(filter)
    dispatch(hideLoader())
    dispatch(setTotalCount(totalCount))
    return data
  }
)

export const getAccountTags = createAsyncThunk('account/tags', async (_, {dispatch}: any) => {
  return await AccountService.getAccountsTags()
})

export const getAccount = createAsyncThunk('account/fetch', async (id: any, {dispatch}: any) => {
  try {
    dispatch(setFetching(true))
    const data = await AccountService.account(id)
    return data[0]
  } catch (error) {
    console.error(error)
  } finally {
    dispatch(setFetching(false))
  }
})

export const updateStore = createAsyncThunk(
  'account/update',
  async ({data, newFilter}: any, {dispatch}) => {
    try {
      dispatch(setSubmitting(true))
      await AccountService.updateStorePlan(data)
      dispatch(
        showMessage({
          text: 'Store updated successfully',
          variant: 'success',
        })
      )
      dispatch(setShowPriceDialog({isShow: false}))
      dispatch(getClientAccount(newFilter))
    } catch (err) {
      console.error(err)
      dispatch(
        showMessage({
          text: 'Error occurred while updating store',
          variant: 'error',
        })
      )
    } finally {
      dispatch(setSubmitting(false))
    }
  }
)

const initialState = {
  accountsList: [],
  store: {},
  showPriceDialog: {
    id: '',
    isShow: false,
  },
  accountTags: [],
  filter: {
    client: '',
    offset: 0,
    pageSize: 25,
    orderBy: 'NAME_ASC',
    tagId: null,
  },
  isSubmitting: false,
  isFetching: false,
  storeLock: {
    lockStatus: '',
    showDialog: false,
    id: null,
  },
}

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setShowPriceDialog: (state, action) => {
      state.showPriceDialog = {...state.showPriceDialog, ...action.payload}
    },
    setFilter: (state, action) => {
      state.filter = {...state.filter, ...action.payload}
    },
    setSubmitting: (state, action) => {
      state.isSubmitting = action.payload
    },
    setFetching: (state, action) => {
      state.isFetching = action.payload
    },
    setStoreLock: (state, action) => {
      state.storeLock = action.payload
    },
  },
  extraReducers: {
    [getAccounts.fulfilled.toString()]: (state, action) => {
      state.accountsList = action.payload
    },
    [getAccount.fulfilled.toString()]: (state, action) => {
      state.store = action.payload
    },
    [getAccountTags.fulfilled.toString()]: (state, action) => {
      state.accountTags = action.payload
    },
  },
})
export const {setShowPriceDialog, setFilter, setSubmitting, setFetching, setStoreLock} =
  accountSlice.actions
export default accountSlice.reducer
