import apolloClient from 'apollo-client'
import {
  CREATE_ACCOUNT_CREATION_LINK,
  DELETE_ACCOUNT_CREATION_LINK,
  GET_ACCOUNT_CREATION_LINK,
} from 'app/features/accountLinkCreation/graphql/AccountCreation'

class AccountCreationService {
  getAccountCreationLinkList = async (params: any) => {
    const {data, errors} = await apolloClient.query({
      query: GET_ACCOUNT_CREATION_LINK,
      variables: params,
    })
    if (errors) {
      throw errors
    }
    return {
      data: data.accountCreationLinkRequests.nodes,
      totalCount: data.accountCreationLinkRequests.totalCount,
    }
  }

  createAccountCreationLinkList = async (params: any) => {
    return await apolloClient.mutate({
      mutation: CREATE_ACCOUNT_CREATION_LINK,
      variables: params,
    })
  }

  deleteAccountCreationLinkList = async (id: any) => {
    return await apolloClient.mutate({
      mutation: DELETE_ACCOUNT_CREATION_LINK,
      variables: {id},
    })
  }
}

const accountCreationService = new AccountCreationService()
export default accountCreationService
