const prodBaseURL = 'https://api.corksy.io'
const prodMigrationBaseAPI = 'https://migrationapi.corksy.io'
const devBaseURL = 'http://localhost:8000'
const stagingBaseURL = '/admin-api'
const demoBaseURL = '/admin-api'
const migrationBaseAPI = '/migration-api'

const prodClientAppDomain = 'https://admin.corksy.io'
const devClientAppDomain = 'http://localhost:3012'
const stagingClientDomain = 'https://staging.corksy.io'
const demoClientAppDomain = 'https://demo.corksy.io'

const prodSuperAdminDomain = 'superadmin.corksy.io'
const devSuperAdminDomain = 'localhost'
const demoSuperAdminDomain = 'superadmin.demo.corksy.io'

let API_URL = configApiUrl()
let MIGRATION_API_URL = configMigrationUrl()
let CLIENT_APP_DOMAIN = configAppDomain()

function configApiUrl() {
  if (window.location.href.includes(prodSuperAdminDomain)) {
    return prodBaseURL
  } else if (window.location.href.includes(devSuperAdminDomain)) {
    return devBaseURL
  } else if (window.location.href.includes(demoSuperAdminDomain)) {
    return demoBaseURL
  } else {
    return stagingBaseURL
  }
}

function configMigrationUrl() {
  if (window.location.href.includes(prodSuperAdminDomain)) {
    return prodMigrationBaseAPI
  } else if (window.location.href.includes(devSuperAdminDomain)) {
    return 'http://127.0.0.1:5000'
  } else {
    return migrationBaseAPI
  }
}

function configAppDomain() {
  if (window.location.href.includes(prodSuperAdminDomain)) {
    return prodClientAppDomain
  } else if (window.location.href.includes(devSuperAdminDomain)) {
    return devClientAppDomain
  } else if (window.location.href.includes(demoSuperAdminDomain)) {
    return demoClientAppDomain
  } else {
    return stagingClientDomain
  }
}

const config = {
  API_URL,
  MIGRATION_API_URL,
  CLIENT_APP_DOMAIN,
}

export default config
